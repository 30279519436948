import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset font-face-gpthr">
        <li>
          <a href="https://discord.com/invite/z8CgTAHBuq" target="__blank">Discord ของเรา</a>
        </li>
        <li>
          <a href="https://discord.com/api/oauth2/authorize?client_id=921408134772293762&permissions=8&scope=bot%20applications.commands" target="__blank">เชิญบอท VALORANT</a>
        </li>
        <li>
          <a href="https://plutoz.kanjaporn.xyz/" target="__blank">เชิญบอทเพลง (Plutoz)</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;