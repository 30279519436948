import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'ง่ายจริง...หรือจ้อจี้ ?',
    paragraph: 'มาดูกันว่าบอท Kanjaporn (Kae) บอทดูร้านค้ารายวัน VALORANT อันดับ #1 ของไทย ใช้งานได้ง่ายขนาดไหน และมีประสิทธิภาพจริงหรือเปล่า ?'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container font-face-gpthr">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content font-face-gpthr" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  ร้านค้ารายวัน
                  </div>
                <h3 className="mt-0 mb-12 font-face-gpthb">
                  เริ่มที่ดูร้านค้ารายวันกันก่อน
                  </h3>
                <p className="m-0">
                  ใช้เพียงคำสั่ง /shop ก็ดูร้านค้ารายวันที่เปลี่ยนใหม่ทุก ๆ วันตอน 7 โมงเช้าได้โดยไม่ต้องเข้าเกมแล้ว
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="เริ่มที่ดูร้านค้ารายวันกันก่อน"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  ตั้งค่าการแจ้งเตือนสกิน
                  </div>
                <h3 className="mt-0 mb-12 font-face-gpthb">
                  ไม่ต้องตื่นเช้ามาดูทุก ๆ วัน
                  </h3>
                <p className="m-0">
                  เพียงพิมพ์ /alert ก็จะเข้าสู่การตั้งค่าการแจ้งเตือนสกินที่คุณอยากได้ คุณไม่จำเป็นต้องตื่นขึ้นมาทุก 7 โมงเช้าเพื่อดูร้านค้ารายวันทั้งหมดของคุณ บอทจะแจ้งคุณทันทีผ่านเซิร์ฟเวอร์ Discord ที่คุณตั้งค่าไว้ เพียงใช้คำสั่งนี้ก็เอาเวลาที่เหลือไปทำอย่างอื่นได้อีกเยอะ
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="ไม่ต้องตื่นเช้ามาดูทุก ๆ วัน"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  แจ้งเตือนสกินเข้าร้านค้ารายวัน
                  </div>
                <h3 className="mt-0 mb-12 font-face-gpthb">
                  แค่นี้ก็เป็นอันเสร็จ
                  </h3>
                <p className="m-0">
                  บอทจะแจ้งเตือนคุณทันทีที่สกินที่คุณตั้งค่าการแจ้งเตือนไว้เข้าร้านค้ารายวันของคุณ ไม่ต้องดูร้านค้ารายวันทุก 7 โมงเช้าทุกวันให้เสียเวลา และบอทจะแจ้งเตือนคุณทันทีภายใน 8 โมงเช้า อีกทั้งยังมีฟีเจอร์เด่น ๆ อีกมากมาย
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="แค่นี้ก็เป็นอันเสร็จ"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;